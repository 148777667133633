<template>
  <div class="hero-banner">
    <USHeroBanner class="hero-banner__banner">
      <div class="overlay"></div>
      <ImageWrapper
        :src="imageHomeHeroBanner"
        class="hero-banner__banner-image"
        alt="Golfers at golf course"
        object-fit="cover"
        object-position="50% 60%"
        height="100%"
        width="100%"
        :fade-in="false"
        :lazy="true"
      />
      <div class="hero-banner__content">
        <div class="grid__row">
          <div class="grid__column grid__column--12">
            <h1 class="hero-banner__heading display--d1 text-align--center">
              Discover. Book. Play.
            </h1>
          </div>
        </div>
      </div>
    </USHeroBanner>
    <!-- 
    <div class="hero-banner__search grid__row">
      <div
        class="grid__column grid__column--12 grid__column--breakpoint--9--10 grid__column--breakpoint--9--offset--1"
      >
        <EventSearchBar
          v-bind="eventSearchBarProps"
          @submitEventSearch="handleEventSearch($event)"
        />
      </div>
    </div>-->
  </div>
</template>

<script lang="ts" setup>
import { SortOrder, ClubSortField } from '__generated__/globalTypes'
import { listAmenities } from '__generated__/listAmenities'
import { listClubs } from '__generated__/listClubs'

import { defineComponent, defineEmits, ref } from 'vue'

import ImageWrapper from '@base/components/ImageWrapper/ImageWrapper.vue'
import USHeroBanner from '@base/components/USHeroBanner/USHeroBanner.vue'

import { useClient } from '@base/api/useClient'
import { LIST_CLUBS_QUERY } from '@base/api/queries/listClubs'

import EventSearchBar from '@player/_components/EventSearchBar/EventSearchBar.vue'

import { LIST_AMENITIES } from '@player/courses/api/queries/listAmenities'

import imageHomeHeroBanner from '@player/static/assets/header-home.jpg'
import { eventAttributesTypes } from '__generated__/eventAttributesTypes'
import { EVENT_ATTRIBUTES_TYPES } from '@player/events/api/queries/eventAttributesTypes'

type EventSearchBarSelectType = {
  label: string
  value: string
}

type EventSearchEvent = {
  startDate: string | null
  endDate: string | null
  lat: number | null
  lon: number | null
  loc: string | null
  type: string[] | []
  clubs: string[] | []
  amenitiesValue: string[] | []
}

defineComponent({
  name: 'HeroBanner',
  components: {
    ImageWrapper,
    USHeroBanner,
    EventSearchBar
  }
})

const client = useClient()

const clubData = ref<EventSearchBarSelectType[]>([])
const clubAmenities = ref<EventSearchBarSelectType[]>([])
const eventTypes = ref<EventSearchBarSelectType[]>([])

const apiKey = import.meta.env.VITE_GCP_GEOCODING_API_KEY

const eventSearchBarProps = {
  apiKey: apiKey,
  buttonLabel: 'Explore Events',
  golfClubSelect: clubData.value,
  amenitiesTypeSelect: clubAmenities.value,
  eventTypeSelect: eventTypes.value
}

const fetchData = async () => {
  let usedClubs = <string[]>[]
  try {
    let { data } = await client.query<listClubs>({
      query: LIST_CLUBS_QUERY,
      variables: {
        filter: {},
        sortField: ClubSortField.NAME,
        sortOrder: SortOrder.ASC,
        limit: 0,
        offset: 0
      },
      fetchPolicy: 'cache-first'
    })

    if (data.listClubs.__typename === 'ListClubsOutput') {
      data.listClubs.clubs.forEach((club) => {
        if (club.id && !usedClubs.includes(club.id)) {
          clubData.value.push({
            label: club.clubName || '',
            value: club.id
          })
          usedClubs.push(club.id)
        }
      })
    }

    let { data: fetchEventTypes } = await client.query<eventAttributesTypes>({
      query: EVENT_ATTRIBUTES_TYPES,
      fetchPolicy: 'no-cache'
    })

    if (fetchEventTypes.fetchEventTypes.__typename === 'AttributesOutput') {
      fetchEventTypes.fetchEventTypes.attributes.forEach((item) => {
        eventTypes.value.push({
          label: item.name,
          value: item.id
        })
      })
    }
  } catch (e) {
    console.error(e)
  }
}

const fetchListOfAmenities = async () => {
  try {
    const { data } = await client.query<listAmenities>({
      query: LIST_AMENITIES
    })

    if (!data || data.fetchAmenities.__typename !== 'AttributesOutput') {
      return
    }

    data.fetchAmenities.attributes.forEach((item) => {
      clubAmenities.value.push({
        label: item.name,
        value: item.id
      })
    })
  } catch (e) {
    console.error(e)
  }
}

// await fetchData()
// await fetchListOfAmenities()

const emits = defineEmits(['submitEventSearch'])

const handleEventSearch = (e: EventSearchEvent) => {
  emits('submitEventSearch', e)
}
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '@base/styles/v1.0/scss/foundations/breakpoint' as bp;

$search-bar-offset-sm: 30px;
$search-bar-offset-lg: 50px;

.hero-banner {
  margin-top: calc(-1 * var(--spacing--5));

  &__content {
    z-index: 2;
    align-items: center;
    bottom: 0;
    color: var(--font-color--neutral-a5);
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    justify-content: center;
    left: 0;
    padding-bottom: $search-bar-offset-sm;
    padding-top: $search-bar-offset-sm;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    @include bp.min-width(bp.$breakpoint--9) {
      padding-bottom: $search-bar-offset-lg;
      padding-top: $search-bar-offset-lg;
    }
  }

  &__heading {
    left: 0;
    position: absolute;
    top: calc(50%);
    transform: translateY(-50%);
    width: 100%;
  }

  &__search {
    margin-top: #{$search-bar-offset-sm * -1};

    @include bp.min-width(bp.$breakpoint--9) {
      margin-top: #{$search-bar-offset-lg * -1};
    }
  }

  .overlay {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .15;
  }
}
</style>
